/**
 * Claim on Suppliers API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ColumnName {
    SapNumber = 'SapNumber',
    CreationDate = 'CreationDate',
    TotalRequested = 'TotalRequested',
    TotalAccepted = 'TotalAccepted',
    ProductLine = 'ProductLine',
    SupplierCsNumber = 'SupplierCsNumber'
};

