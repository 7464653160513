import { Pipe, PipeTransform } from '@angular/core';
import { PositionStatus } from '../../../shared/generated';
import { EditablePosition } from './claim-positions.component';

@Pipe({ name: 'cosPositionValidPipe', pure: false })
export class PositionValidPipe implements PipeTransform {
  transform(position: EditablePosition): boolean {
    switch (position.edit_mode) {
      case PositionStatus.Accepted:
      case PositionStatus.AcceptedPartially: {
        return Boolean(
          (position.edit_reason?.length || 0) > 4 && position.refund_type,
        );
      }
      case PositionStatus.Declined:
      case PositionStatus.FurtherInformationNecessary: {
        return Boolean((position.edit_reason?.length || 0) > 4);
      }
      default:
        return true;
    }
  }
}
