import { Pipe, PipeTransform } from '@angular/core';
import { Position } from './generated';

@Pipe({ name: 'cosTotalClaimPipe', pure: false })
export class TotalClaimPipe implements PipeTransform {
  transform(
    positions: Position[],
    calculate: 'accepted' | 'requested' | 'progress',
  ): number {
    const total_requested = positions.reduce((total, position) => {
      total += position.requested_value;
      return parseFloat(total.toFixed(12));
    }, 0);
    const total_accepted = positions.reduce((total, position) => {
      total += position.accepted_value;
      return parseFloat(total.toFixed(12));
    }, 0);
    if (calculate === 'progress') {
      return total_accepted
        ? Math.floor((total_accepted / total_requested) * 100)
        : 0;
    } else if (calculate === 'accepted') {
      return total_accepted;
    } else {
      return total_requested;
    }
  }
}
