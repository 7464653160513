export class DateUtils {
  static parseISODateString(dateString?: string): Date | undefined {
    if (!dateString) return;

    const split = dateString.split('-', 3);
    if (split.length !== 3) throw new Error('Invalid date ' + dateString);

    return new Date(Number(split[0]), Number(split[1]) - 1, Number(split[2]));
  }

  static toISODateStringUTC(date?: Date): string {
    if (!date || !isValidDate(date)) return '';

    return (
      date.getUTCFullYear() +
      '-' +
      ('0' + (date.getUTCMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getUTCDate()).slice(-2)
    );
  }

  static toISODateStringLocal(date?: Date): string {
    if (!date) return '';

    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2)
    );
  }

  //should return full month difference.
  //expected results:
  // expect(DateUtils.monthDiff(new Date(2010, 0, 1), new Date(2010, 2, 12))).toBe(2); 1st jan -> 1st feb -> 1st march are 2 full months
  // expect(DateUtils.monthDiff(new Date(2010, 1, 1), new Date(2010, 2, 12))).toBe(1); 1st jan -> 1st feb is one full month
  // expect(DateUtils.monthDiff(new Date(2010, 1, 1), new Date(2010, 1, 12))).toBe(0); 1st jan -> 12st jan is 0 full month
  // expect(DateUtils.monthDiff(new Date(2011, 1, 1), new Date(2010, 1, 12))).toBe(0); start date is bigger then end date
  static monthDiff(startDate: Date, endDate: Date): number {
    if (startDate >= endDate) {
      return 0;
    }
    let months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    if (startDate.getDate() === endDate.getDate()) {
      ///the same day in another month is counted as whole month
      months -= startDate.getMonth();
    } else {
      //count only full months, 2018-01-05 and 2018-02-10 is only one full month (and some days left), not 2 months
      months -= startDate.getMonth() + 1;
    }
    if (startDate.getDate() < endDate.getDate()) {
      months += endDate.getMonth() + 1;
    } else {
      months += endDate.getMonth();
    }
    return months <= 0 ? 0 : months;
  }

  //returns date bigger or smaller within the month value from given date.
  //addMonths('2018-08-23', -2); Result: 2018-06-23
  static addMonths(date: Date, months: number): Date {
    const newDate = new Date(date);
    return new Date(newDate.setMonth(newDate.getMonth() + months));
  }

  static getDurationInSeconds(startDateTime: Date, endDateTime: Date): number {
    return (+endDateTime - +startDateTime) / 1000;
  }

  static clearTime(date: Date | undefined | null): Date | undefined {
    if (!date) {
      return undefined;
    }
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static isInRange(
    startTime: number,
    expirationTime: number,
    filterStartTime: number,
    filterEndTime: number,
  ): boolean {
    return (
      (startTime >= filterStartTime && startTime <= filterEndTime) ||
      (expirationTime >= filterStartTime && expirationTime <= filterEndTime) ||
      (startTime <= filterStartTime && expirationTime >= filterEndTime)
    );
  }
}

export function isValidDate(d: any) {
  return !isNaN(d) && d instanceof Date;
}
