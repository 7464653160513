<div gaCategoryGroup="ClaimDetails">
  <ng-container *ngIf="claim$ | async as claim; else loading">
    <ds-page-sticky-header>
      <h1 fxLayout="row wrap" fxLayoutAlign="space-between baseline">
        {{ claim.sap_number }}
        <div fxLayout [fxLayoutGap]="1 | dsSpacing">
          <button
            mat-button
            page-action
            [disabled]="!canSendToSap() || savingClaim"
            (click)="send(claim.id)"
            data-cy="send_button"
            gaClickListener="SendToSAP"
          >
            <mat-progress-spinner
              [mode]="'indeterminate'"
              *ngIf="savingClaim"
              [diameter]="14"
            ></mat-progress-spinner>
            <mat-icon *ngIf="!savingClaim">send</mat-icon>
            {{ 'cos.send' | translate | titlecase }}
          </button>
        </div>
      </h1>
    </ds-page-sticky-header>

    <cos-claim-overview [claim]="claim"></cos-claim-overview>
    <br />
    <mat-form-field class="full-width">
      <mat-label>{{ 'cos.supplier_cs_number' | translate }}</mat-label>
      <input
        matInput
        [formControl]="csnumber"
        autocomplete="off"
        maxlength="100"
        data-cy="supplier_number"
      />
    </mat-form-field>
    <br />
    <cos-claim-positions
      [claimPositions]="claim.positions"
    ></cos-claim-positions>
    <cos-claim-attachments
      [attachmentsFromBE]="claimAttachments"
      [readonlyMode]="!isAnyPositionEditable()"
    ></cos-claim-attachments>
    <br />
  </ng-container>
  <ng-template #loading>
    <mat-progress-bar
      *ngIf="!loadingError; else loadingErrorTemplate"
      mode="indeterminate"
    ></mat-progress-bar>
    <ng-template #loadingErrorTemplate
      ><ds-placeholder type="error"></ds-placeholder>
    </ng-template>
  </ng-template>
</div>
