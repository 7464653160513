import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable()
export class UnsavedDataGuard {
  messageText: string;
  constructor(private translateService: TranslateService) {
    this.translateService
      .get('general.are_you_sure_to_leave')
      .pipe(take(1))
      .subscribe((x) => (this.messageText = x));
  }

  canDeactivate(component: UnsavedDataGuarded): boolean {
    if (component.hasUnsavedData()) {
      return confirm(this.messageText);
    } else {
      return true;
    }
  }
}

export interface UnsavedDataGuarded {
  hasUnsavedData: () => boolean;
}
