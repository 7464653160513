<div gaCategoryGroup="ClaimList">
  <div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
    <div fxFlex="0 0 auto">
      <h1 fxLayout="row wrap" fxLayoutAlign="space-between baseline">
        {{ 'cos.desk' | translate }}
        <div fxLayout [fxLayoutGap]="1 | dsSpacing">
          <button
            mat-button
            page-action
            (click)="exportClaims()"
            [disabled]="exportRequestPending"
            gaClickListener="ExportClaims"
          >
            <mat-icon>cloud_download</mat-icon>
            {{ 'cos.export' | translate }}
            <mat-progress-spinner
              [mode]="'indeterminate'"
              *ngIf="exportRequestPending"
              [diameter]="15"
            ></mat-progress-spinner>
          </button>
        </div>
      </h1>
    </div>

    <ds-table *pdLet="claims$ | async as claims">
      <ds-filter [formGroup]="filter" (resetFilter)="filter.reset()">
        <mat-form-field *dsFilterItem="filter.get('claim_type')">
          <mat-label>{{
            'cos.claim_status.claim_status' | translate
          }}</mat-label>
          <mat-select
            formControlName="claim_type"
            data-cy="claim_status"
            [multiple]="true"
            gaClickListener="FilterByClaimStatus"
          >
            <mat-option
              *ngFor="let status of objectKeys(positionStatus)"
              [value]="status"
            >
              {{ 'cos.claim_status.' + status | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *dsFilterItem="filter.get('sap_number')">
          <mat-label>{{ 'cos.sap_number' | translate }}</mat-label>
          <input
            matInput
            formControlName="sap_number"
            data-cy="notification_number"
            gaClickListener="FilterBySAPNumber"
          />
        </mat-form-field>
        <mat-form-field *dsFilterItem="filter.get('supplier_cs_number')">
          <mat-label>{{ 'cos.supplier_cs_number' | translate }}</mat-label>
          <input
            matInput
            formControlName="supplier_cs_number"
            data-cy="supplier_cs_number"
            gaClickListener="FilterBySupplierCsNumber"
          />
        </mat-form-field>
        <mat-form-field *dsFilterItem="filter.get('serial_number')">
          <mat-label>{{ 'cos.serial_number' | translate }}</mat-label>
          <input
            matInput
            formControlName="serial_number"
            data-cy="serial_number"
            gaClickListener="FilterBySerialNumber"
          />
        </mat-form-field>
        <mat-form-field *dsFilterItem="filter.get('end_date')">
          <mat-label>{{ 'cos.creation_date' | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Start date"
              formControlName="start_date"
              data-cy="start_date"
              gaClickListener="FilterByCreationDate"
            />
            <input
              matEndDate
              placeholder="End date"
              formControlName="end_date"
              data-cy="end_date"
              gaClickListener="FilterByCreationDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
            gaClickListener="FilterByCreationDate"
          ></mat-datepicker-toggle>
          <mat-datepicker-toggle
            matIconSuffix
            (click)="
              filter.get('start_date')?.reset(); filter.get('end_date')?.reset()
            "
          >
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field *dsFilterItem="filter.get('product_line')">
          <mat-label>{{ 'cos.product_line' | translate }}</mat-label>
          <mat-select
            formControlName="product_line"
            data-cy="product_line"
            [multiple]="true"
            gaClickListener="FilterByProductLine"
          >
            <mat-option
              *ngFor="
                let productLine of [
                  'CRANE',
                  'CRAWLER',
                  'EPSILON',
                  'HOOKLOADER',
                  'PLATFORM',
                  'RAILWAY',
                  'SKIPLOADER',
                  'TMF',
                  'EMPTY'
                ]
              "
              [value]="productLine"
            >
              {{ 'cos.product_lines.' + productLine | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ds-filter>

      <ng-container *ngIf="claims; else loading">
        <table
          mat-table
          matSort
          *ngIf="claims.rows.length; else noResult"
          [dataSource]="claims.rows"
          (matSortChange)="sortChange($event)"
          [matSortActive]="sortActive"
          [matSortDirection]="sortDirection"
          data-cy="cos_table"
        >
          <ng-container [matColumnDef]="columnName.SapNumber">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              gaClickListener="SortBySapNumber"
            >
              {{ 'cos.sap_number' | translate }}
            </th>
            <td mat-cell *matCellDef="let claim">
              <a [routerLink]="[claim.claim_id]" gaClickListener="GoToClaim">
                {{ claim.sap_number }}
              </a>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="columnName.SupplierCsNumber">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              gaClickListener="SortBySupplierCSNumber"
            >
              {{ 'cos.supplier_cs_number' | translate }}
            </th>
            <td mat-cell *matCellDef="let claim">
              {{ claim.supplier_cs_number || 'general.n_a' | translate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="statuses">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'cos.claim_status.claim_status' | translate }}
            </th>
            <td mat-cell *matCellDef="let claim">
              <mat-chip-listbox>
                <mat-chip-option *ngFor="let status of claim.desk_statuses">
                  {{ 'cos.claim_status.' + status.status | translate }}
                  <ng-container *ngIf="status.count > 1">
                    ({{ status.count }})
                  </ng-container>
                </mat-chip-option>
              </mat-chip-listbox>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="columnName.CreationDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              gaClickListener="SortByCreationDate"
            >
              {{ 'cos.creation_date' | translate }}
            </th>
            <td mat-cell *matCellDef="let claim">
              {{
                claim.creation_date
                  ? (claim.creation_date | date: 'shortDate')
                  : ('general.not_available' | translate)
              }}
            </td>
          </ng-container>

          <ng-container [matColumnDef]="columnName.TotalRequested">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              gaClickListener="SortByRequested"
            >
              {{ 'cos.requested' | translate }}
            </th>
            <td mat-cell *matCellDef="let claim">
              {{ claim.total_requested | currency: 'EUR' }}
            </td>
          </ng-container>

          <ng-container [matColumnDef]="columnName.TotalAccepted">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              gaClickListener="SortByAccepted"
            >
              {{ 'cos.accepted' | translate }}
            </th>
            <td mat-cell *matCellDef="let claim">
              {{ claim.total_accepted | currency: 'EUR' }}
            </td>
          </ng-container>

          <ng-container [matColumnDef]="columnName.ProductLine">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              gaClickListener="SortByProductLine"
            >
              {{ 'cos.product_line' | translate }}
            </th>
            <td mat-cell *matCellDef="let claim" [style.width]="1 | dsSpacing">
              {{ claim.product_line || 'general.n_a' | translate }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns; let i = index"
          ></tr>
        </table>
      </ng-container>
      <ds-table-footer>
        <mat-paginator
          fxFlex
          [length]="claims?.row_count"
          [pageSizeOptions]="[10, 20, 100]"
          (page)="getClaims()"
          [cdkStorePageSize]="'cos_table_pagesize'"
          gaClickListener="UsePaginator"
        ></mat-paginator>
      </ds-table-footer>
    </ds-table>
  </div>

  <ng-template #loading>
    <ng-container *ngIf="loadingError; else loadingPlacehodder">
      <ds-placeholder type="error"></ds-placeholder>
    </ng-container>
    <ng-template #loadingPlacehodder>
      <ds-table-loading
        [numberOfHeaders]="displayedColumns.length"
        [numberOfRows]="5"
      >
      </ds-table-loading>
    </ng-template>
  </ng-template>
  <ng-template #noResult>
    <ds-placeholder
      type="no_data"
      [customHeadline]="'cos.no_claims' | translate"
    ></ds-placeholder>
  </ng-template>
</div>
