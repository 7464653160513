import { Component, Input } from '@angular/core';
import { ClaimDetailResponse } from '../../../shared/generated';

@Component({
  selector: 'cos-claim-overview',
  templateUrl: './claim-overview.component.html',
  styleUrls: ['./claim-overview.component.scss'],
})
export class ClaimOverviewComponent {
  @Input() claim: ClaimDetailResponse;

  mailTo(email: string) {
    window.location.href = `mailto:${email}`;
  }
}
