import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StorePageSizeDirective } from './store-page-size.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [StorePageSizeDirective],
  exports: [StorePageSizeDirective],
})
export class StorePageSizeDirectiveModule {}
