import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes, Route } from '@angular/router';
import { APPS } from '@config';
import { AuthGuard } from '@features/auth';
import { UnsavedDataGuard } from '@utils/unsaved-data-guard';
import { ClaimDetailsComponent } from './claims/claim-details/claim-details.component';
import { ClaimListComponent } from './claims/claim-list/claim-list.component';
import { selectedID } from './shared/root.selectors';

export const appRoutes: Routes = [
  {
    path: '',
    data: {
      permissions: {
        roles: [APPS.CLAIM_ON_SUPPLIERS_NEW.ROLES.USER],
      },
    },
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'claims',
      },
      {
        path: 'claims',
        pathMatch: 'full',
        component: ClaimListComponent,
      },
      {
        path: 'claims/:id',
        component: ClaimDetailsComponent,
        data: {
          breadcrumb: selectedID,
        },
        canDeactivate: [
          (component: ClaimDetailsComponent) =>
            inject(UnsavedDataGuard).canDeactivate(component),
        ],
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'help',
    loadChildren: () =>
      import('@paldesk/design-system/feature/help-page').then(
        (m) => m.DSHelpPageModule,
      ),
    canMatch: [(next: Route) => inject(AuthGuard).canMatch(next)],
    data: {
      breadcrumb: 'general.help',
    },
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
