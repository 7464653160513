import { Directive, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Directive({
  selector: '[cdkStorePageSize]',
})
export class StorePageSizeDirective {
  _localStorageKey: string;

  get localStorageKey(): string {
    return this._localStorageKey;
  }

  @Input('cdkStorePageSize')
  set localStorageKey(value: string) {
    this._localStorageKey = value;

    /**
     * Try get stored pageSize in localStorage
     * If not found - try get first value of pageSizeOptions
     * If not found - fallback to '10'
     */
    const local_value = localStorage.getItem(this._localStorageKey);
    if (local_value) {
      this.element.pageSize = parseInt(local_value, 10);
    } else {
      this.element.pageSize =
        this.element.pageSizeOptions && this.element.pageSizeOptions.length
          ? this.element.pageSizeOptions[0]
          : 10;
    }
  }

  constructor(private element: MatPaginator) {
    this.element.page.subscribe((e) => {
      localStorage.setItem(this._localStorageKey, e.pageSize.toString());
    });
  }
}
