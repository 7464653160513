import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FileSizePipe],
  exports: [FileSizePipe],
  providers: [DecimalPipe],
})
export class FileSizePipeModule {}
