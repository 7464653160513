import { HttpEventType } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AttachedFile } from '@design-system/cdk/file-drop';
import { finalize } from 'rxjs';
import {
  AttachmentResponse,
  ClaimService,
  FileService,
} from '../../../shared/generated';
import { SaveAttachmentsResponse } from '../../../shared/generated/model/saveAttachmentsResponse';
import { ApiError, ErrorHandlingService } from '@utils/error-handling-service';

@Component({
  selector: 'cos-claim-attachments',
  templateUrl: './claim-attachments.component.html',
  styleUrls: ['./claim-attachments.component.scss'],
})
export class ClaimAttachmentsComponent {
  @Input() readonlyMode: boolean;
  @Input() set attachmentsFromBE(val: AttachmentResponse[]) {
    if (val) {
      this.setAttachments(val);
    }
  }
  attachmentsToShow: AttachmentFile[] = [];
  fileOptions = {
    maxUploadSizeInMb: 200,
    supportedFilesTypes: [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'pdf',
      'psy',
      'rrc',
      'p50',
      'p100',
      'p40',
      'p150',
      'pal',
      'pwp',
      'ppr',
      'dxf',
      'zip',
      'rar',
      'mp4',
      'pecu',
      'prrc',
      'psys',
      'ars',
      'msg',
      'mov',
      'txt',
      'hevc',
    ],
  };

  constructor(
    private fileService: FileService,
    private claimService: ClaimService,
    private route: ActivatedRoute,
    private errorHandlingService: ErrorHandlingService,
  ) {}

  // eslint-disable-next-line sonarjs/cognitive-complexity
  uploadFiles(files: File[]) {
    if (files.length) {
      for (let i = 0; i < files.length; ++i) {
        const file = files[i];
        const attachedFile: AttachmentFile = {
          id: '',
          name: file.name,
          size: file.size,
          progress: 0,
          url: '',
          sent_to_sap: false,
          delete_pending: false,
        };
        this.attachmentsToShow = [...this.attachmentsToShow, attachedFile];
        if (file)
          this.fileService.uploadFile(file, 'events', true).subscribe({
            next: (response) => {
              if (response.type === HttpEventType.Response) {
                attachedFile.url = response.body?.file_uri || '';
                attachedFile.progress = 100;
                if (
                  this.attachmentsToShow.every(
                    (x) => x.url && x.progress === 100,
                  )
                ) {
                  this.saveAttachmentsToBe();
                }
              } else if (response.type === HttpEventType.UploadProgress) {
                attachedFile.progress = Math.round(
                  (response.loaded * 100) / (response.total || 1),
                );
              }
            },
            error: (err: ApiError) =>
              this.errorHandlingService.handleError(
                err,
                'cos.upload_file_error',
              ),
          });
      }
    }
  }

  setAttachments(files: AttachmentResponse[]) {
    this.attachmentsToShow = files.map((f) => ({
      progress: 100,
      url: f.file_uri,
      size: f.file_size,
      name: f.file_name,
      id: f.attachment_id.toString(),
      sent_to_sap: f.sent_to_sap,
      delete_pending: false,
    }));
  }

  deleteFile(file: AttachmentFile) {
    if (!file.id) {
      this.attachmentsToShow.filter((a) => a !== file);
      return;
    }
    file.delete_pending = true;
    this.claimService
      .deleteAttachment(parseInt(file.id, 10))
      .pipe(finalize(() => (file.delete_pending = false)))
      .subscribe({
        next: () =>
          (this.attachmentsToShow = this.attachmentsToShow.filter(
            (a) => a.id !== file.id,
          )),
        error: (err: ApiError) =>
          this.errorHandlingService.handleError(err, 'cos.delete_file_error'),
      });
  }

  saveAttachmentsToBe() {
    this.claimService
      .claimOnSuppliersSaveAttachments({
        attachments: this.attachmentsToShow
          .filter((att) => att.id === '')
          .map((att) => ({
            file_name: att.name,
            file_uri: att.url || '',
            file_size: att.size,
          })),
        claim_id: this.route.snapshot.params.id,
      })
      .subscribe({
        next: (response: SaveAttachmentsResponse) => {
          if (response.saved_attachments?.length) {
            response.saved_attachments.forEach((savedAttachement) => {
              const attachment = this.attachmentsToShow.find(
                (x) => x.url === savedAttachement.file_uri,
              );
              if (attachment) {
                attachment.id = savedAttachement.attachment_id.toString();
              }
            });
          }
        },
        error: (err: ApiError) =>
          this.errorHandlingService.handleError(err, 'cos.upload_file_error'),
      });
  }
}

export interface AttachmentFile extends AttachedFile {
  sent_to_sap: boolean;
  delete_pending: boolean;
}
