import { Injectable } from '@angular/core';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

export interface ApiError {
  type: string;
  title: string;
  status: number;
  instance: string;
  errors?: Record<string, string[]>;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(
    private snackbar: DsSnackbarService,
    private translateService: TranslateService,
  ) {}

  handleError(
    error: HttpErrorResponse | ApiError,
    fallbackMessageKey?: string,
  ): void {
    let message: string;

    if (error.status === 500) {
      const logId =
        error instanceof HttpErrorResponse
          ? error.error?.instance
          : error.instance;
      message = this.translateService.instant(
        'general.error_code.server_error',
        {
          logId,
        },
      );
    } else {
      let errors: Record<string, string[]> | undefined;

      if (error instanceof HttpErrorResponse) {
        errors =
          error.error && error.error.errors ? error.error.errors : undefined;
      } else {
        errors = error.errors;
      }

      const firstErrorMessage = this.extractFirstErrorMessage(errors);
      message =
        firstErrorMessage ||
        this.translateService.instant(
          fallbackMessageKey || 'general.error_code.error',
        );
    }

    this.snackbar.queue(message, {
      type: DsSnackbarType.Error,
    });
  }

  private extractFirstErrorMessage(
    errors?: Record<string, string[]>,
  ): string | null {
    if (!errors) {
      return null;
    }
    const firstErrorArray = Object.values(errors)[0];
    return firstErrorArray ? firstErrorArray[0] : null;
  }
}
