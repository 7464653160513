import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { matExpansionAnimations } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { dsAnimations } from '@design-system/cdk/animations';

@Component({
  selector: 'ds-expansion-panel',
  templateUrl: './absolute-expansion-panel.component.html',
  styleUrl: './absolute-expansion-panel.component.scss',
  animations: [
    matExpansionAnimations.indicatorRotate,
    dsAnimations.autoHeightExpansion,
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
  ],
})
export class DsAbsoluteExpansionPanelComponent {
  @Input() zIndex: number;
  @Input() isContentFaded = true;

  isExpanded = false;
}
