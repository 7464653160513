import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isImage',
  standalone: true,
})
export class IsImagePipe implements PipeTransform {
  transform(fileName: string): boolean {
    const ext = this.getExtensionFromFileName(fileName);
    return (
      ext === 'jpeg' ||
      ext === 'jpg' ||
      ext === 'gif' ||
      ext === 'png' ||
      ext === 'webp'
    );
  }

  private getExtensionFromFileName(fileName: string) {
    return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
  }
}
